import {TopBarMenu} from "../header/vertical/vertical.types";
import {MenuItem} from "./menu-settings.config";


export class GlobalVars {
    /**
     * トップバーメニュー情報
     */
    public static TOP_BAR_MENU_SETTINGS: TopBarMenu = {
        alert: null,
        notification: null,
        message: null,
        help: null,
        user: null,
        locale: null,
    };

    /**
     * メインメニュー情報
     */
    public static VERTICAL_MENU: { items: Partial<MenuItem>[] } = {items: []};

    /**
     * _themeSettingsServiceのconfig設定
     */
    public static BRAND_INIT_FUNCTION: InitFunctionNoParam = null;

    /**
     * _menuSettingsConfig設定
     */
    public static MENU_FILTER_FUNCTION: InitFunctionObject2 = null;

}

/**
 * パラメータなしのメソッド
 */
export type InitFunctionNoParam = () => void;
/**
 * パラメータあり（二個）のメソッド
 */
export type InitFunctionObject2 = (target: any, source: any) => void;
