// Default menu settings configurations

import {GlobalVars} from "./global-vars";
import {initVerticalMenu} from "../../config/MenuConst";


export interface MenuItem {
    title: string;
    icon: string;
    page: string;
    isExternalLink?: boolean;
    issupportExternalLink?: boolean;
    isStarterkitExternalLink?: boolean;
    badge: { type: string, value: string };
    submenu: {
        items: Partial<MenuItem>[];
    };
    section: string;
    click: string;
    isMgr: boolean;
    type: string;
    opts: string[];
}

export interface MenuConfig {
    horizontal_menu: {
        items: Partial<MenuItem>[]
    };
    vertical_menu: {
        items: Partial<MenuItem>[]
    };
    // 関数定義
}

initVerticalMenu();

export const MenuSettingsConfig: MenuConfig = {
    horizontal_menu: {items: []},
    vertical_menu: GlobalVars.VERTICAL_MENU,
};

