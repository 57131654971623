import {environment} from '../../../environments/environment';


export class ConstantsHandler {

    // サーバー
    static server = environment.accUrl.server;
    // BSサーバー
    static bsServer = environment.accUrl.bsServer;
    // キークロックサーバー
    static keycloakServer = environment.keycloak.server;
    // キーId
    static keycloak_client_id = environment.keycloak.clientId;

    static targetuserid = environment.targetUser.id;
    static targetuserCompanyid = environment.targetUser.companyId;

    static watchServer = environment.httpEnv.watchServer;
    static httpEnvironment = environment.httpEnv;

    static USER_TOKEN_INVALID = 'ユーザー安全認証トークンが無効になりました';
    static SUCCESS_INFO = 'SUCCESS';

    static multiLanguage = environment.multiLanguage;

    static PIC_ONLINE = "assets/marker/header-man-background-green.svg";
    static PIC_OFFLINE = "assets/marker/header-man-background-grey.svg";
    static PIC_ALERT = "assets/marker/header-man-background-red.svg";
    static PIC_AVATAR_URL = "assets/images/portrait/small/default.png";

    // static server = "https://demo.trackun.jp/iotPF";
    // static server = "https://demo.trackun.jp/v1.0";
    // static bsServer = "http://localhost:8081";
    // static keycloakServer = "https://auth.trackun.jp/auth/realms/trackun/"
    // static keycloakServer = "https://auth.trackun.jp/"
    // static keycloakServer = "http://localhost:8080/auth/realms/trackun/"
    // サーバー（リリース）
    // static watchServer = "http://47.91.30.55/";

    static APP_WEB_TITLE = environment.appSetting.webTitle;
    static APP_MENU_TITLE = environment.appSetting.menuTitle;
    static APP_MENU_ICON = environment.appSetting.menuIcon;
    static APP_LOGIN_TITLE = environment.appSetting.loginTitle;
    static APP_LOGIN_ICON = environment.appSetting.loginIcon;
    static APP_LOGIN_BACKGROUND = environment.appSetting.loginBackground;
    static APP_LOGIN_JUMP = environment.appSetting.loginJump;
    static APP_VERTICAL_BACKGROUND_COLOR = environment.appSetting.verticalBackgroundColor;

    static TOKEN = environment.other.TOKEN;
    static tokenStatus = environment.other.tokenStatus;
    static GLOBAL_TOKEN = environment.other.GLOBAL_TOKEN;
    static LOCAL_STORAGE = environment.other.LOCAL_STORAGE;

}

export class ServerType {
    static loginServer = 1;
    static server = 2;
    static bsServer = 3;
}
