import {FirebaseDataService, HttpRequest} from "ifocus-lib";
import {HttpHeaders} from "@angular/common/http";
import {ConstantsHandler} from "../_common/_constant/constants.handler";
import {Injectable} from "@angular/core";
import {UtilService} from "./UtilService";


@Injectable()
export abstract class BsBaseHttpRequest implements HttpRequest {

    protected constructor(
        protected firebaseDataService: FirebaseDataService,
        protected utilService: UtilService,
    ) {
    }

    getBaseUrl(): string {
        return ConstantsHandler.httpEnvironment.businessServer;
    }

    getHeaders(): HttpHeaders {
        let token = this.getToken();
        if (!token) {
            token = '';
        }

        let headers = new HttpHeaders({
            'Authorization': token
        });

        this.addHeaders(headers);

        return headers;
    }

    errorHandler(err: any): void {
        if (!err) {
            return;
        }

        // BAD_REQUEST（業務チェック）
        if (err.status === 400) {
            return;
        }
        // UNAUTHORIZED（ログイン検証）
        if (err.status === 401) {
            return;
        }
        // FORBIDDEN （トークン検証）
        if (err.status === 403) {
            this.utilService.logoutForTokenError(err);
            return;
        }

    }

    private getToken(): string {
        let token: string = this.firebaseDataService.get(ConstantsHandler.GLOBAL_TOKEN.id);
        if (token && token !== '') {
            return token;
        }
        return null;
    }

    protected abstract addHeaders(headers: HttpHeaders): void;


}
