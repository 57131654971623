import {Injectable} from "@angular/core";
import {FirebaseDataService} from "ifocus-lib";
import {HttpHeaders} from "@angular/common/http";
import {BsBaseHttpRequest} from "./BsBaseHttpRequest";
import {UtilService} from "./UtilService";


@Injectable()
export class BsHttpRequestForm extends BsBaseHttpRequest {

    constructor(
        protected firebaseDataService: FirebaseDataService,
        protected utilService: UtilService,
    ) {
        super(firebaseDataService, utilService);
    }

    protected addHeaders(headers: HttpHeaders): void {
    }


}
