import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../_services/auth.service";
import {AlertService} from "../_services/alert.service";
// import {UserService} from "../_services/UserService";
import {CookieService} from "ngx-cookie-service";
import {ConstantsHandler} from "../_common/_constant/constants.handler";
import {CommonConfirmDialogService} from "../_services/common.confirm.dialog.service";
// import {CommonToastMessageService} from "../_services/common.toast.message.service";
// import {WebNotificationMessageService} from "../_services/web-notification-message.service";
import {
    FirebaseDataService,
    IfcsComponentBase,
    IfcsVerifyCodeComponent,
    IfcsVerifyCodeService,
    LanguageService,
    LocalDataService,
    UtilFuncService,
} from "ifocus-lib";
import {BsHttpRequest} from "../utils/BsHttpRequest";
import {HttpSimpleService} from "../utils/HttpSimpleService";
import {SelfUserInfoService} from "../utils/SelfUserInfoService";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import {UtilService} from "../utils/UtilService";
import {TranslateService} from "@ngx-translate/core";
import {getDefaultLanguage, getTransMessage} from "../config/MenuConst";
import {StringConst} from "../utils/UtilConstString";


@Component({
    templateUrl: "login.component.html",
    styleUrls: ["./login.component.css"],
})
export class LoginComponent extends IfcsComponentBase implements OnInit, AfterViewInit {
    @ViewChild('verifyCode')
    private verifyCodeComponent: IfcsVerifyCodeComponent;

    private isEmailLoginFlag = true;

    title: string = ConstantsHandler.APP_LOGIN_TITLE;
    icon: string = ConstantsHandler.APP_LOGIN_ICON;
    background: string =
        "background-image: url('" + ConstantsHandler.APP_LOGIN_BACKGROUND + "');";

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    isPageLoaded = false;

    uid: string;

    localeList = [];
    selectedLocaleId: string;

    // 電話番号認証ID
    verificationId: string;
    // ログインユーザの電話番号
    phoneNumber: string;
    // 電話番号チェックコード
    phoneCode: string;
    phoneCheck: boolean = false;
    phoneSubmit: boolean = false;

    safariFlag = false;


    constructor(
        // private userService: UserService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        private cookieService: CookieService,
        private confirmationService: CommonConfirmDialogService,
        // private messageService: CommonToastMessageService,
        // private webNotificationMessageService: WebNotificationMessageService,
        private utilFuncService: UtilFuncService,
        private verifyCodeService: IfcsVerifyCodeService,
        private bsHttpRequest: BsHttpRequest,
        private httpSimpleService: HttpSimpleService,
        private firebaseDataService: FirebaseDataService,
        private localDataService: LocalDataService,
        private selfUserInfoService: SelfUserInfoService,
        private utilService: UtilService,
        protected language: LanguageService,
        protected translate: TranslateService
    ) {
        // 多言語対応
        super(language, translate);

        this.route.queryParams.subscribe((params) => {
            this.returnUrl = params["returnUrl"];
        });
    }

    ngOnInit() {
        super.ngOnInit();

        this.init();
        this.isPageLoaded = true;
        localStorage.clear();
        this.localDataService.deleteAll();
        this.firebaseDataService.deleteAll();

        this.setLocaleList();
        // this.setLanguage(getDefaultLanguage());
        this.selectedLocaleId = getDefaultLanguage(this.translate);

        // this.loginForm.controls["selectedLocaleId"].setValue(
        //     this.selectedLocaleId,
        //     {onlySelf: true}
        // );

        const userAgent = window.navigator.userAgent.toLowerCase();
        if ((userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1 && userAgent.indexOf("edge") === -1)
            || userAgent.indexOf("flutter_ios") !== -1) {
            // ここにSafari用の記述
            this.safariFlag = true;
        }
    }

    //チェックコード
    ngAfterViewInit() {
        this.selectedLocaleId = getDefaultLanguage(this.translate);
        this.clickLocale();
        
        // this.checkCodeDraw();
        this.verifyCodeService.show("verifyCode");
    }

    init() {
        let SuccessCookie: string = this.cookieService.get(
            ConstantsHandler.SUCCESS_INFO
        );
        if (SuccessCookie !== null && SuccessCookie !== "") {
            let SuccessInfo = JSON.parse(SuccessCookie);
            this.alertService.success(SuccessInfo);
        }
        this.cookieService.delete(ConstantsHandler.SUCCESS_INFO);
        this.loginForm = this.formBuilder.group({
            // selectedLocaleId: ["", Validators.required],
            email: ["", Validators.required],
            password: ["", Validators.required],
            checkcode: ["", Validators.required],
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onChangeSelect(event) {
        this.selectedLocaleId = event.target.value;
        this.clickLocale();
    }

    tryLogin() {
        // this.selectedLocaleId = this.f.selectedLocaleId.value;
        // console.log(this.selectedLocaleId);

        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.submitted = false;
            return;
        }

        const checkCodeList = this.verifyCodeComponent.getVerifyCode();
        if (this.f.checkcode.value !== checkCodeList) {
            this.submitted = false;
            // this.alertService.error('確認コードが異なります。');
            this.alertService.error(
                getTransMessage(this.translate, "missConfirmCode")
            );
            // this.checkCodeDraw();
            this.verifyCodeService.show("verifyCode");
            return;
        }

        let value = {
            email: UtilFuncService.zeroWidthTrim(this.f.email.value.trim()),
            password: this.f.password.value,
            refreshToken: "",
            token: "",
        };

        // ログイン
        this.httpSimpleService
            .post<any, any>(value, "login", this.bsHttpRequest)
            .then(async (result) => {
                // console.log(result);
                let user = result.signInToken.firebaseUser;

                // 本登録済の場合
                if (user.emailVerified) {
                    // 設定処理
                    this.setInfoForLogin(result);
                    return;
                }

                value.token = result.signInToken.token;
                // 仮登録の場合
                // メール認証
                this.confirmationService.confirm({
                    // message: "メールアドレスを確認することが必要となります、<br>認証のために、「確認」ボタンを押下してください。",
                    // header: 'メール認証',
                    message: getTransMessage(this.translate, "msg_mailAddrConfirm"),
                    header: getTransMessage(this.translate, "emailAuthentication"),
                    accept: () => {
                        this.httpSimpleService
                            .post<any, any>(value, "login/sendEmail/", this.bsHttpRequest)
                            .then((result) => {
                                this.utilFuncService.showAlert(
                                    "info",
                                    getTransMessage(this.translate, "msg_sendAuthMail")
                                );
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    },
                    reject: () => {
                    },
                });
                this.submitted = false;
                this.logout();
            })
            .catch((err) => {
                console.log(err);
                // this.checkCodeDraw();
                this.verifyCodeService.show("verifyCode");
                this.submitted = false;
                this.alertService.error(
                    getTransMessage(this.translate, "msg_loginFail")
                );
            });
    }

    /**
     * 検証メールを再送する
     * @param event イベント
     */
    resendMail(event) {
        if (!this.f.email.value || !this.f.password.value) {
            this.utilFuncService.showAlert(
                "error",
                getTransMessage(this.translate, "msg_inputUnamePassword")
            );
            return;
        }

        let value = {
            email: this.f.email.value,
            password: this.f.password.value,
            token: "",
        };

        this.httpSimpleService
            .post<any, any>(value, "login/loginUser/", this.bsHttpRequest)
            .then((result) => {
                let user = result.firebaseUser;
                // 検証済の場合
                if (user.emailVerified) {
                    this.utilFuncService.showAlert(
                        "info",
                        getTransMessage(this.translate, "msg_verfiedUser")
                    );
                    return;
                }
                // 仮登録の場合
                value.token = result.token;
                // メール認証
                this.httpSimpleService
                    .post<any, any>(value, "login/sendEmail/", this.bsHttpRequest)
                    .then((result) => {
                        this.utilFuncService.showAlert(
                            "info",
                            getTransMessage(this.translate, "msg_sendAuthMail")
                        );
                    })
                    .catch((err) => {
                        this.utilFuncService.showAlert(
                            "error",
                            getTransMessage(this.translate, "msg_sendFailResend")
                        );
                        console.error(err);
                    });
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.submitted = false;
                this.logout();
            });
    }

    /**
     * パスワードをリセットする
     * @param event イベント
     */
    restPassword(event) {
        const value = {
            email: this.f.email.value,
        };
        if (!value.email) {
            this.utilFuncService.showAlert(
                "error",
                getTransMessage(this.translate, "msg_inputUsername")
            );
            return;
        }

        this.httpSimpleService
            .post<any, any>(value, "login/restPassword/", this.bsHttpRequest)
            .then((result) => {
                this.utilFuncService.showAlert(
                    "info",
                    getTransMessage(this.translate, "msg_definePwdResetMail")
                );
            })
            .catch((err) => {
                console.log(err);
                this.alertService.error(
                    getTransMessage(this.translate, "msg_failuarSendPwdResetMail")
                );
            });
    }

    /**
     * メールでログインイベント
     */
    onEmailLoginClick(): void {
        this.isEmailLoginFlag = true;
        // console.log(event);
        setTimeout(() => this.verifyCodeService.show("verifyCode"), 0);
    }

    /**
     * 電話番号でログインイベント
     */
    onPhoneNumberLoginClick(): void {
        this.isEmailLoginFlag = false;
    }

    /**
     * 電話に認証コードを送信する
     */
    async onSendCode(): Promise<void> {
        // チェック処理
        if (this.checkPhoneNumber()) {
            return;
        }

        this.phoneCheck = true;

        // 電話番号
        const phoneNumber = UtilService.makeJpnPhoneNumber(this.phoneNumber);

        // 存在チェック
        this.httpSimpleService
            .post<any, any>(phoneNumber, "login/phoneNumber/", this.bsHttpRequest)
            .then(async (result) => {
                // 存在しない場合
                if (!result! || !result.firebaseUser) {
                    this.phoneCheck = false;
                    this.utilFuncService.showAlert(
                        "warn",
                        getTransMessage(this.translate, "msg_userUnexist")
                    );
                    return;
                }
                // 認証コード送信
                await this.verifyPhoneNumber(phoneNumber);
            })
            .catch((e) => {
                console.log(e);
                this.phoneCheck = false;
            });
    }

    /**
     * 電話認証コードでログインのイベント
     */
    onPhoneNumberLogin(): void {
        // チェック処理
        if (this.checkPhoneNumber()) {
            return;
        }

        this.phoneSubmit = true;
        const param = {
            phoneNumber: UtilService.makeJpnPhoneNumber(this.phoneNumber),
            code: this.phoneCode,
            sessionInfo: this.verificationId,
        };
        // ログイン
        this.httpSimpleService
            .post<any, any>(param, "login/phone", this.bsHttpRequest)
            .then((result) => {
                // 設定処理
                this.setInfoForLogin(result);
            })
            .catch((err) => {
                console.log(err);
                this.alertService.error(
                    getTransMessage(this.translate, "msg_loginFail")
                );
            });
        this.phoneSubmit = false;
    }

    get isEmailLogin(): boolean {
        return this.isEmailLoginFlag;
    }

    /**
     * ログアウト処理
     */
    private logout(): void {
        this.authService.doLogout().then(
            (res) => {
                this.router.navigate(["/"]);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    setLocaleList() {
        // await this.translate.get("en").toPromise();
        this.localeList = [];

        ConstantsHandler.multiLanguage.supportLangs.forEach((locale) => {
            this.localeList.push({
                localeId: locale,
                localeName: getTransMessage(this.translate, locale),
            });
        });
    }

    // getLocaleList() {
    //     console.log(
    //         "getLocaleList",
    //         localStorage.getItem("CURRENT_LANG"),
    //         this.selectedLocaleId
    //     );
    //     this.localeList = [];
    //
    //     ConstantsHandler.multiLanguage.supportLangs.forEach((locale) => {
    //         this.localeList.push({
    //             localeId: locale,
    //             localeName: getTransMessage(this.translate, locale),
    //         });
    //     });
    //     this.selectedLocaleId = localStorage.getItem("CURRENT_LANG");
    // }

    /**
     * 電話に認証コード送信
     * @param phoneNumber 携帯電話番号
     */
    private async verifyPhoneNumber(phoneNumber: string): Promise<void> {
        let appVerifier = new firebase.auth.RecaptchaVerifier("code", {
            size: "invisible",
        });

        // 電話認証プロバイダー取得
        let provider = new firebase.auth.PhoneAuthProvider();
        try {
            // 認証コードを発送する
            this.verificationId = await provider.verifyPhoneNumber(
                phoneNumber,
                appVerifier
            );
            // console.log(this.verificationId);
            this.utilFuncService.showAlert(
                "info",
                this.phoneNumber +
                getTransMessage(this.translate, "msg_sendVerfiedCode")
            );
            this.phoneCheck = false;
        } catch (error) {
            console.log(error);

            // 電話番号が間違った
            this.utilFuncService.showAlert(
                "error",
                getTransMessage(this.translate, "msg_inputValidTelNo")
            );
            this.phoneCheck = false;
        }
    }

    private clickLocale(): void {
        let current = localStorage.getItem("CURRENT_LANG");
        // console.log("clickLocale", current, this.selectedLocaleId);
        if (current !== this.selectedLocaleId) {
            // 変更した言語設定
            localStorage.setItem("CURRENT_LANG", this.selectedLocaleId);
            // 言語変更
            this.language.setLanguage(this.selectedLocaleId);
        }
    }

    private checkPhoneNumber(): boolean {
        return this.utilService.formatCheck(StringConst.REG_PHONE_NUMBER, this.phoneNumber, getTransMessage(this.translate, 'telNo81'), this.translate);
    }

    private setInfoForLogin(result: any): void {
        // token保存
        this.firebaseDataService.setTokenCookie(
            ConstantsHandler.GLOBAL_TOKEN.id,
            result.signInToken
        );
        // グループ設定
        result.user.group = result.user.groups[0].groupCode;

        // // local storage保存
        // this.localDataService.set(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER, result.user);
        // // ログイン情報設定
        // this.selfUserInfoService.setSelfUserInfo(result.user);
        // // firebase通知
        // await this.webNotificationMessageService.register();

        // ロケールリスト設定
        this.selfUserInfoService.setLocaleList(result.localeList);

        // 多言語のディフォルト設定
        const current = this.selectedLocaleId;
        localStorage.setItem("CURRENT_LANG", current);

        result.localeList.forEach((locale) => {
            if (locale.localeId === current) {
                result.user.locale = locale;
            }
        });

        // ログイン情報設定
        this.selfUserInfoService.setSelfUserInfo(result.user);

        // バージョンアップなしの場合
        if (!result.signInToken.versionConfirm) {
            this.router.navigate([ConstantsHandler.APP_LOGIN_JUMP]);
        }
    }


}
