import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable()
export class VerticalService {
    // アラート
    private updateAlertCall = new Subject<any>();
    public updateAlertCall$ = this.updateAlertCall.asObservable();
    // 通知
    private updateNoticeCall = new Subject<any>();
    public updateNoticeCall$ = this.updateNoticeCall.asObservable();
    // ログインユーザー情報
    private refreshProfileCall = new Subject<any>();
    public refreshProfileCall$ = this.refreshProfileCall.asObservable();
    // タイトル情報
    private refreshBrandCall = new Subject<any>();
    public refreshBrandCall$ = this.refreshBrandCall.asObservable();
    // メニュー情報
    private refreshMenuCall = new Subject<any>();
    public refreshMenuCall$ = this.refreshMenuCall.asObservable();


    public updateAlert() {
        this.updateAlertCall.next({});
    }

    public updateNotice() {
        this.updateNoticeCall.next({});
    }

    public refreshProfile() {
        this.refreshProfileCall.next({});
    }

    public refreshBrand() {
        this.refreshBrandCall.next({});
    }

    public refreshMenu() {
        this.refreshMenuCall.next({});
    }

}
