import {Injectable} from "@angular/core";
import {StringUtilService, UtilFuncService} from "ifocus-lib";
import {LiveStatusConst} from "./UtilConstType";
import {ConstantsHandler} from "../_common/_constant/constants.handler";
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {TranslateService} from "@ngx-translate/core";


@Injectable()
export class UtilService {

    constructor(
        protected utilFuncService: UtilFuncService,
        protected _router: Router,
        protected authService: AuthService,
    ) {
    }


    public logoutForTokenError(err): void {
        let userName: string;
        if (err) {
            userName = err.error.userName;
        }
        this.authService.doLogout().then(res => {
            this._router.navigate([''], {
                queryParams: {
                    message: ConstantsHandler.GLOBAL_TOKEN.errMsg.userTokenInvalid,
                    userName: userName
                }
            });
        }, err => {
            console.log(err);
        });
    }

    public requiredCheck(target: any, msg: string, translate?: TranslateService) {
        return this.utilFuncService.requiredCheck(target, msg, 'warn', translate);
    }

    public formatCheck(format: string, target: string, msg: string, translate?: TranslateService) {
        return this.utilFuncService.formatCheck(format, target, msg, 'warn', translate);
    }

    public static isOnlineDevice(liveStatus: string): boolean {
        if (UtilFuncService.isStringEmpty(liveStatus)) {
            return false;
        }
        return liveStatus === LiveStatusConst.LIVE_STATUS_ROOM
            || liveStatus === LiveStatusConst.LIVE_STATUS_LIVE;
    }

    public static isLiveDevice(liveStatus: string): boolean {
        if (UtilFuncService.isStringEmpty(liveStatus)) {
            return false;
        }
        return liveStatus === LiveStatusConst.LIVE_STATUS_LIVE;
    }

    /**
     * 携帯電話番号作成（日本のみ）
     */
    public static makeJpnPhoneNumber(phoneNumber: string): string {
        if (StringUtilService.isStringEmpty(phoneNumber)) {
            return "";
        }
        let number = "";
        const regex = new RegExp(/^(0)(\d*)/);
        if (regex.test(phoneNumber)) {
            number = phoneNumber.match(regex)[2];
        }
        return "+81" + number;
    }

    public static makeJpnPhoneNumberForShow(phoneNumber: string): string {
        if (StringUtilService.isStringEmpty(phoneNumber)) {
            return "";
        }
        return phoneNumber.replace('+81', '0');
    }

    /**
     * 個人・会社設定情報取得
     * @param currentUser ログインユーザー情報
     */
    public static getCurrentUserCompanySettings(currentUser: any): any {
        // 会社設定情報リスト
        const companySettings = currentUser.companySettings;
        // 個人設定情報リスト
        const userSettings = currentUser.userSettings;
        // ロケール情報リスト
        const locale = currentUser.locale;

        // 会社アイコン設定情報取得
        let companyIcon = null;
        if (companySettings && companySettings.length > 0) {
            // アイコン
            const setting = companySettings.find(c => c.type === '01');
            if (setting) {
                companyIcon = JSON.parse(setting.values);
            }
        }

        // 個人アイコン設定情報取得
        let userIcon = null;
        if (userSettings && userSettings.length > 0) {
            // アイコン
            const setting = userSettings.find(c => c.type === '03');
            if (setting) {
                userIcon = JSON.parse(setting.values);
            }
        }

        // // ロケール情報取得
        // let locale = null;
        // if (locales && locales.length > 0) {
        //     // ロケール
        //     const setting = locales.find(c => c.type === '01');
        //     if (setting) {
        //         locale = setting.values;
        //     }
        // }

        // 戻り値
        return {
            companyIcon: companyIcon,
            userIcon: userIcon,
            locale: locale
        };
    }

    /**
     * 文字列のスペースをトリムする
     * @param str 指定した文字列
     */
    public static trimString(str: string): string {
        return StringUtilService.isStringEmpty(str) ? str : str.trim();
    }


}
