import {Injectable} from "@angular/core";
import {WatchHttpRequest} from "./WatchHttpRequest";
import {HttpBaseService, HttpRequest} from "ifocus-lib";

// イベント処理関数
export type ProcessFunction<T, R> = (param: T) => Promise<R>;


@Injectable()
export class HttpSimpleService {

    constructor(
        private httpBase: HttpBaseService,
        private watchHttp: WatchHttpRequest,
    ) {
    }


    public post<T, R>(param: T, url: string, request?: HttpRequest): Promise<R> {
        let req: HttpRequest = this.watchHttp;
        if (request) {
            req = request;
        }
        return new Promise<R>((resolve, reject) => {
            this.httpBase.post(url, param, req).then(result => {
                resolve(result);
            }).catch(e => {
                reject(e);
            });
        });
    }

    public postDownload<T, R>(param: T, url: string, request?: HttpRequest): Promise<R> {
        let req: HttpRequest = this.watchHttp;
        if (request) {
            req = request;
        }
        return new Promise<R>((resolve, reject) => {
            this.httpBase.postDownload(url, param, req).then(result => {
                resolve(result);
            }).catch(e => {
                reject(e);
            });
        });
    }

    public get<R>(url: string, request?: HttpRequest): Promise<R> {
        let req: HttpRequest = this.watchHttp;
        if (request) {
            req = request;
        }
        return new Promise<any>((resolve, reject) => {
            this.httpBase.get(url, req).then(result => {
                if (result) {
                    resolve(result);
                }
            }).catch(e => {
                reject(e);
            });
        });
    }


}

