import {Params} from "@angular/router";


/**
 * コールバック情報
 */
export class CallBackInfo {
    // リンク
    routerLink: string;
    // リンクパラメータ
    queryParams: Params | null;
}

/**
 * 通知詳細情報
 */
export interface NotificationDetail {
    // タイトル
    title: string;
    // 詳細
    detail: string;
    // 時間
    timestamp: string;
}

/**
 * 通知情報
 */
export class NotificationInfo extends CallBackInfo {
    // 件数
    count: Number;
    // 詳細リスト
    list: NotificationDetail[] = [];
}

/**
 * 多言語情報
 */
export class LocaleInfo extends CallBackInfo {
    // 詳細リスト
    localeList: any[] = [];
}


export type InitFunction = (This: any, info: CallBackInfo) => any | null;
export type ClickFunction = (This: any, param: any) => any | null;

/**
 * トップバーメニュー情報
 */
export interface TopBarMenu {
    // アラート
    alert: TopBarMenuSettings;
    // 通知
    notification: TopBarMenuSettings;
    // メッセージ配信
    message: TopBarMenuSettings;
    // ヘルプ
    help: TopBarMenuSettings;
    // 個人情報
    user: ProfileUserSettings;
    // ロケール
    locale: TopBarMenuSettings;
}

/**
 * トップバーメニュー設定情報
 */
export interface TopBarMenuSettings {
    // 有効フラグ
    isUse: boolean;
    // 初期化処理
    initFunction: InitFunction;
    // クリック処理
    clickFunction: ClickFunction;
    // データ
    data?: any;
}

/**
 * 個人情報
 */
export class ProfileUserInfo extends CallBackInfo {
    // 名称
    name: string;
    // メール
    email: string;
    // 個人画像
    avatarUrl: string;
    // 個人情報
    loginUser: any;
    // ロケール
    locale: any;
}

/**
 * 個人ニュー設定情報
 */
export interface ProfileUserSettings extends TopBarMenuSettings {
    // 個人
    profile: TopBarMenuSettings;
    // ヘルプ
    help: TopBarMenuSettings;
    // マニュアル
    manual: TopBarMenuSettings;
    // グループ
    group: TopBarMenuSettings;
    // 個人リフレッシュ
    update: TopBarMenuSettings;
}
