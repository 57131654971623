import {Injectable} from "@angular/core";
import {ConstantsHandler} from "../_common/_constant/constants.handler";
import {HttpHeaders} from "@angular/common/http";
import {CookieDataService, HttpRequest} from "ifocus-lib";
import {UtilService} from "./UtilService";


@Injectable()
export class WatchHttpRequest implements HttpRequest {

    constructor(
        private cookieDataService: CookieDataService,
        protected utilService: UtilService,
    ) {
    }

    getBaseUrl(): string {
        return ConstantsHandler.httpEnvironment.verifyServer;
    }

    getHeaders(): HttpHeaders {
        let token = this.getToken();
        if (!token) {
            token = '';
        }
        return new HttpHeaders({
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': token
        });
    }

    errorHandler(error: any): void {
        // FORBIDDEN （トークン検証）
        if (error.status === 401) {
            this.utilService.logoutForTokenError(error);
            return;
        }
    }

    private getToken(): string {
        let token: string = this.cookieDataService.get(ConstantsHandler.GLOBAL_TOKEN.id);
        if (token && token !== '') {
            return token;
        }
        return null;
    }

}
