import { GlobalVars } from "../_layout/settings/global-vars";
import {
  LocaleInfo,
  NotificationInfo,
  ProfileUserInfo,
} from "../_layout/header/vertical/vertical.types";
import { Injectable } from "@angular/core";
import { BsHttpRequest } from "../utils/BsHttpRequest";
import { HttpSimpleService } from "../utils/HttpSimpleService";
import { WatchHttpRequest } from "../utils/WatchHttpRequest";
import { SelfUserInfoService } from "../utils/SelfUserInfoService";
import {
  LanguageService,
  StringUtilService,
  UtilFuncService,
} from "ifocus-lib";
import { VerticalService } from "../_layout/header/vertical/vertical.service";
import { ConstantsHandler } from "../_common/_constant/constants.handler";
import { UtilService } from "../utils/UtilService";
import { Subject } from "rxjs";
import { ThemeSettingsService } from "../_layout/settings/theme-settings.service";
import { TranslateService } from "@ngx-translate/core";

export function initVerticalMenu(): void {
  GlobalVars.VERTICAL_MENU = verticalMenu;
}

export function getTransMessage(
  translate: TranslateService,
  key: string
): string {
  return LanguageService.getTransMessage(translate, key);
}

export function getDefaultLanguage(translate: TranslateService): string {
  return LanguageService.getDefaultLanguage(
    translate,
    ConstantsHandler.multiLanguage
  );
}

/**
 * メインメニュー情報
 */
const verticalMenu = {
  items: [
    {
      // title: 'ホーム',
      title: "home",
      icon: "la-home",
      page: "/home",
    },
    {
      // title: 'ライブビュー',
      title: "liveView",
      icon: "la-video-camera",
      page: "/live",
    },
    {
      // title: 'ライブビュー',
      title: "liveTest2",
      icon: "la-video-camera",
      page: "/live-test2",
    },
    {
      // title: '録画映像',
      title: "recordVideo",
      icon: "la-file-video-o",
      page: "/video",
    },
    {
      // title: 'デバイス一覧',
      title: "deviceList",
      icon: "la-user",
      page: "/live-user-list",
    },

    // {section: '管理メニュー', icon: 'la-ellipsis-h', type: 'general'},
    { section: "manageMenu", icon: "la-ellipsis-h", type: "general" },
    {
      // title: 'ダッシュボード',
      title: "dashboard",
      icon: "la-dashboard",
      page: "/dashboard",
      type: "general",
    },
    {
      // title: 'デバイス管理',
      title: "deviceManage",
      icon: "la-camera",
      page: "/device",
      type: "general",
    },
    {
      // title: 'ユーザー管理',
      title: "userManage",
      icon: "la-user",
      page: "null",
      type: "general",
      submenu: {
        items: [
          {
            // title: 'ユーザー',
            title: "userE",
            icon: "la-user",
            page: "/user",
          },
          {
            // title: 'デバイスユーザー',
            title: "deviceUser",
            icon: "la-user-secret",
            page: "/wearer",
          },
          {
            // title: 'デバイス紐付け履歴',
            title: "deviceBinddingHistory",
            icon: "la-gg-circle",
            page: "/binding-list",
          },
        ],
      },
    },

    {
      // title: 'ICカード管理',
      title: "icCardManage",
      icon: "feather ft-credit-card",
      page: "/ic-card",
      type: "general",
    },

    {
      // title: 'グループ管理',
      title: "groupManage",
      icon: "la-group",
      page: "/group",
      type: "general",
    },

    {
      // title: '会社情報管理',
      title: "companyInfoManage",
      icon: "la-cog",
      page: "/setting",
      type: "general",
    },

    {
      // title: 'アラート管理',
      title: "alertManagement",
      icon: "la-bell",
      page: "/alert-notifications",
      type: "general",
    },

    // {
    //     title: '権限管理',
    //     icon: 'icon-lock-open',
    //     page: '/user-auth'
    // },

    // 管理メニュー
    { section: "MANAGEMENT", icon: "la-ellipsis-h", isMgr: true },
    {
      // title: 'ライセンス',
      title: "license",
      icon: "pi pi-key",
      page: "/t-license",
      isMgr: true,
    },
    {
      // title: 'バージョン（デバイス）',
      title: "versionDevice",
      icon: "la-cogs",
      page: "/t-version",
      isMgr: true,
    },
    {
      // title: 'バージョン（OS）',
      title: "versionOs",
      icon: "la-cogs",
      page: "/t-os-version",
      isMgr: true,
    },
    {
      title: "versionSetting",
      icon: "la-flag-checkered",
      page: "/t-version-setting",
      isMgr: true,
    },
    {
      title: "plan",
      icon: "feather ft-sliders",
      page: "/t-plan",
      isMgr: true,
    },

    // // オプション関連
    // {section: 'OPTIONS', icon: 'la-ellipsis-h', type: 'opt', opts: ['title']},
    // {
    //     title: 'WIFI設定',
    //     icon: 'la-dashboard',
    //     page: '/room-wifi',
    //     type: 'opt',
    //     opts: ['wifi'], //cloudSMS: true, mqtt: false, wifi: true
    // },
    //
    //
    // // サポート関連
    // // { section: 'サポート', icon: 'la-ellipsis-h' },
    // {section: 'SUPPORT', icon: 'la-ellipsis-h', type: 'spt'},
    // {
    //     title: 'マニュアル',
    //     icon: 'icon-book-open',
    //     click: 'manual',
    //     type: 'spt',
    // },
    // {
    //     title: 'バージョン情報',
    //     icon: 'feather ft-info',
    //     click: 'version',
    //     badge: {type: 'badge-danger', value: 'NEW'},
    //     type: 'spt'
    // },

    // // テンプレートメニュー
    // {section: 'TEMPLATE', icon: 'la-ellipsis-h', isMgr: true},
    // {
    //     title: 'test',
    //     icon: 'la-map-signs',
    //     page: '/test',
    //     isMgr: true,
    // },
  ],
};

@Injectable()
export class MenuConstService {
  static HELP_FILE_URL: string =
    "assets/help/AAASBodyCamera_Web_Consoleご利用マニュアル.pdf";
  static HELP_FILE_NAME: string =
    "AAASBodyCamera_WebConsoleご利用マニュアル.pdf";

  static MANUAL_FILE_URL: string = "assets/help/AAASBodyCamera_UserGuide.pdf";
  static MANUAL_FILE_NAME: string =
    "AAASBodyCamera_UserGuideご利用マニュアル.pdf";

  private currenUserCall = new Subject<any>();

  constructor(
    private bsHttpRequest: BsHttpRequest,
    private watchHttpRequest: WatchHttpRequest,
    private httpSimpleService: HttpSimpleService,
    private selfUserInfoService: SelfUserInfoService,
    private verticalService: VerticalService,
    private _themeSettingsService: ThemeSettingsService,
    private language: LanguageService
  ) {
    // メニュー初期化
    this.initMenu();
    // ログインユーザー情報
    this.currenUserCall.asObservable().subscribe(() => {
      this.getCurrentUserInfo();
    });
  }

  public initMenu(): void {
    // メニュー設定
    GlobalVars.VERTICAL_MENU = verticalMenu;
    GlobalVars.TOP_BAR_MENU_SETTINGS = this.topBarMenuSettings;

    // _themeSettingsServiceのconfig設定
    GlobalVars.BRAND_INIT_FUNCTION = this.initBrand.bind(this);
    // _menuSettingsConfig設定
    GlobalVars.MENU_FILTER_FUNCTION = this.filterMenu.bind(this);
  }

  public getCurrentUser() {
    this.currenUserCall.next({});
  }

  /**
   * トップバーメニュー情報
   */
  private topBarMenuSettings = {
    alert: {
      isUse: false,
      initFunction: this.initAlert.bind(this),
      clickFunction: null,
    },
    notification: {
      isUse: false,
      initFunction: this.initNotification.bind(this),
      clickFunction: null,
    },
    message: {
      isUse: false,
      initFunction: null,
      clickFunction: this.clickMessage.bind(this),
    },
    help: {
      isUse: true,
      initFunction: null,
      clickFunction: null,
      data: {
        fileUrl: MenuConstService.HELP_FILE_URL,
        fileName: MenuConstService.HELP_FILE_NAME,
      },
    },
    user: {
      isUse: true,
      initFunction: this.initProfileUser.bind(this),
      clickFunction: null,
      profile: {
        isUse: true,
        initFunction: null,
        clickFunction: null,
      },
      help: {
        isUse: true,
        initFunction: null,
        clickFunction: null,
        data: {
          fileUrl: MenuConstService.HELP_FILE_URL,
          fileName: MenuConstService.HELP_FILE_NAME,
        },
      },
      manual: {
        isUse: true,
        initFunction: null,
        clickFunction: null,
        data: {
          fileUrl: MenuConstService.MANUAL_FILE_URL,
          fileName: MenuConstService.MANUAL_FILE_NAME,
        },
      },
      group: {
        isUse: true,
        initFunction: null,
        clickFunction: this.clickGroup.bind(this),
      },
      update: {
        isUse: true,
        initFunction: null,
        clickFunction: this.clickRefresh.bind(this),
      },
    },
    locale: {
      isUse: true,
      initFunction: this.initLocale.bind(this),
      clickFunction: this.clickLocale.bind(this),
    },
  };

  ////////////////////////////////////////////////////////////////////
  //////////////////////////// イベント処理 ////////////////////////////
  ////////////////////////////////////////////////////////////////////
  private initAlert(This: any, info: NotificationInfo): any {
    this.httpSimpleService
      .get<any>(
        "user-notification/alert/list?pageSize=9999&pageNumber=1&unreadOnly=true",
        this.watchHttpRequest
      )
      .then((result) => {
        try {
          if (result) {
            info.count = result.total;
            info.list = [];
            info.routerLink = "/alert-notifications";
            let list = Object.assign([], result.currentPage.slice(0, 5));
            list.forEach((item) => {
              info.list.push({
                title: item.sourceUserName,
                detail: item.detail,
                timestamp: item.createdTimestamp,
              });
            });
            // this.unread = this.noticeList.length - this.read
            localStorage.setItem(
              "globalAlertList",
              JSON.stringify(result.currentPage)
            );
          }
        } catch (e) {
          console.log("エラー　発生しました。");
        }
      });
  }

  private initNotification(This: any, info: NotificationInfo): any {
    this.httpSimpleService
      .get<any>(
        "user-notification/notice/list?unreadOnly=true",
        this.watchHttpRequest
      )
      .then((result) => {
        try {
          if (result) {
            info.count = result.total;
            info.list = [];
            info.routerLink = "/alert-notifications";
            info.queryParams = { type: "notice" };
            let list = Object.assign([], result.currentPage.slice(0, 5));
            list.forEach((item) => {
              info.list.push({
                title: item.notificationType,
                detail: item.detail,
                timestamp: item.createdTimestamp,
              });
            });
            // this.unread = this.noticeList.length - this.read
          }
        } catch (e) {
          console.log("エラー　発生しました。");
        }
      });
  }

  private clickMessage(This: any, modalContent: any): void {
    This.myMessageListModal = This.modalService1.open(modalContent, {
      windowClass: "animated fadeInDown",
      size: "lg",
      centered: true,
    });
  }

  private initProfileUser(This: any, info: ProfileUserInfo): any {
    // let profileUserInfo = this.localDataService.get(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER);
    const profileUserInfo = this.selfUserInfoService.selfUserInfo();

    info.avatarUrl = ConstantsHandler.PIC_AVATAR_URL;
    info.routerLink = "/profile-setting";
    if (profileUserInfo) {
      // 該当なユーザー情報設定
      const currentUser: any = Object.assign({}, profileUserInfo);

      info.loginUser = currentUser;
      // グループ名前を取得
      const groupName = currentUser.groups.find(
        (item) => item.groupCode === currentUser.group
      ).groupName;
      // 情報設定処理
      info.name =
        currentUser.company.name + "　[" + groupName + "]　" + currentUser.name;

      info.email = currentUser.email;
      // 画像ありの場合
      if (currentUser.picture) {
        info.avatarUrl =
          currentUser.picture.baseUrl + currentUser.picture.origin;
      }

      // 個人・会社設定解析
      const userCompanySettings =
        UtilService.getCurrentUserCompanySettings(currentUser);
      if (
        userCompanySettings.userIcon &&
        userCompanySettings.userIcon.urlPath
      ) {
        info.avatarUrl = StringUtilService.urlForDatetime(
          userCompanySettings.userIcon.urlPath
        );
      }
    }
  }

  private clickGroup(This: any, info: ProfileUserInfo): void {
    this.selfUserInfoService.setSelfUserInfo(info.loginUser);
    // 画面リフレッシュ処理
    location.reload();
  }

  private clickRefresh(This: any, param: any): void {
    this.getCurrentUserInfo();
  }

  private initLocale(This: any, info: LocaleInfo): void {
    info.localeList = this.selfUserInfoService.getLocaleList();
  }

  private clickLocale(This: any, locale: any): void {
    const current = localStorage.getItem("CURRENT_LANG");
    if (current !== locale.localeId) {
      // 変更した言語設定
      localStorage.setItem("CURRENT_LANG", locale.localeId);
      this.setUserLang();
      // 言語変更通知
      this.language.setLanguage(locale.localeId);
    }
  }

  private setUserLang() {
    let user: any = this.selfUserInfoService.getSelfUserInfo();
    const current = this.language.getCurrentLanguage();
    this.selfUserInfoService.getLocaleList().forEach((locale) => {
      if (locale.localeId === current) {
        user.locale = locale;
      }
    });

    this.selfUserInfoService.setSelfUserInfo(user);
  }

  /**
   * ブランド表示情報初期化
   */
  private initBrand(): void {
    // ログイン管理者情報
    const profileUserInfo = this.selfUserInfoService.selfUserInfo();

    if (profileUserInfo) {
      // 該当なユーザー情報設定
      const currentUser: any = Object.assign({}, profileUserInfo);
      // 個人・会社設定解析
      const userCompanySettings =
        UtilService.getCurrentUserCompanySettings(currentUser);
      if (userCompanySettings.companyIcon) {
        const name = userCompanySettings.companyIcon.companyIconName;
        const url = StringUtilService.urlForDatetime(
          userCompanySettings.companyIcon.urlPath
        );
        this.doChangeBrand({ name: name, logoUrl: url });
      }
    }
  }

  /**
   * 権限により、メインメニュー表示処理
   * @param config 処理対象
   * @param source 参照対象
   */
  private filterMenu(config: any, source: any): void {
    // // 最新バージョン取得
    // let version = config.vertical_menu.items.filter(item => item.title === 'バージョン情報');
    // if (version && version.length > 0) {
    //     this.httpService.getLatestVersion().then((val) => {
    //         // version[0].badge.value = val.versionName;
    //     });
    // }

    // メニュー設定
    let allMenu = Object.assign([], source.vertical_menu.items);

    // 普通メニュー取得
    let normalMenu = allMenu.filter(
      (item) => !item.isMgr && item.type !== "opt" && item.type !== "spt"
    );
    config.vertical_menu.items = Object.assign([], normalMenu);

    // 固定メニュー取得
    let fixedMenu = allMenu.filter(
      (item) =>
        !item.isMgr &&
        item.type !== "opt" &&
        item.type !== "spt" &&
        item.type !== "general"
    );

    //////////////////////////// カスタマイズ メニュー ////////////////////////////
    // オプションメニュー取得
    let optMenu = allMenu.filter((item) => item.type === "opt");

    // サポートメニュー取得
    let supperMenu = allMenu.filter((item) => item.type === "spt");

    // ログインユーザー情報
    const profileUserInfo = this.selfUserInfoService.selfUserInfo();
    if (!profileUserInfo) {
      return;
    }

    if (profileUserInfo.flagManager) {
      // 個人・会社設定解析
      const userCompanySettings =
        UtilService.getCurrentUserCompanySettings(profileUserInfo);
      // 個人設定情報ありの場合
      if (userCompanySettings.userIcon) {
        if (userCompanySettings.userIcon.uiType === "special") {
          config.vertical_menu.items = Object.assign([], fixedMenu);
        } else {
          config.vertical_menu.items = Object.assign([], normalMenu);
        }
        // 個人設定情報なしの場合
      } else {
        if (
          userCompanySettings.companyIcon &&
          userCompanySettings.companyIcon.uiType === "special"
        ) {
          config.vertical_menu.items = Object.assign([], fixedMenu);
        } else {
          config.vertical_menu.items = Object.assign([], normalMenu);
        }
      }
    } else {
      config.vertical_menu.items = Object.assign([], fixedMenu);
    }

    // 会社オプション情報
    if (profileUserInfo.companyOptions) {
      // cloudSMS: true, mqtt: false, wifi: true
      let optMenuItem = [];
      let title = optMenu.filter(
        (item) => item.opts && item.opts.includes("title")
      );
      let cloudSMS = optMenu.filter(
        (item) => item.opts && item.opts.includes("cloudSMS")
      );
      let mqtt = optMenu.filter(
        (item) => item.opts && item.opts.includes("mqtt")
      );
      let wifi = optMenu.filter(
        (item) => item.opts && item.opts.includes("wifi")
      );
      // クラウドSMSあり
      if (profileUserInfo.companyOptions.cloudSMS) {
      }
      // MQTTあり
      if (profileUserInfo.companyOptions.mqtt) {
      }
      // WIFIあり
      if (profileUserInfo.companyOptions.wifi) {
        optMenuItem.push(...wifi);
      }
      if (optMenuItem.length > 0) {
        config.vertical_menu.items.push(...title);
        config.vertical_menu.items.push(...optMenuItem);
      }
    }

    // サポート
    config.vertical_menu.items.push(...supperMenu);

    // システム管理者の場合
    if (profileUserInfo.role === 0 || profileUserInfo.role === 99) {
      // if (profileUserInfo.role === 0) {
      config.vertical_menu.items = Object.assign([], allMenu);
    }
  }

  ////////////////////////////////////////////////////////////////////
  //////////////////////////// 内部処理 ////////////////////////////
  ////////////////////////////////////////////////////////////////////
  private getCurrentUserInfo() {
    // ログイン管理者情報
    const profileUserInfo = this.selfUserInfoService.selfUserInfo();

    // ログイン管理者情報最新取得
    const url = UtilFuncService.formatString(
      "user/info?email={0}",
      profileUserInfo.email
    );
    this.httpSimpleService
      .get<any>(url, this.bsHttpRequest)
      .then((result) => {
        if (result) {
          let user = Object.assign({}, result);
          user.group = user.groups[0].groupCode;

          // 言語設定取得
          const current = localStorage.getItem("CURRENT_LANG");
          this.selfUserInfoService.getLocaleList().forEach((locale) => {
            if (locale.localeId === current) {
              user.locale = locale;
            }
          });

          // ログイン情報設定
          this.selfUserInfoService.setSelfUserInfo(user);

          // コールバック処理
          this.verticalService.refreshProfile();
          this.verticalService.refreshBrand();
          this.verticalService.refreshMenu();

          // 画面リフレッシュ処理
          location.reload();
        }
      })
      .catch((e) => console.log(e));
  }

  private doChangeBrand(param: { name: string; logoUrl: string }) {
    this._themeSettingsService.config = {
      brand: {
        brand_name: param.name,
        logo: {
          type: "internal", // internal, url
          value: param.logoUrl, // recommended location for custom images
        },
      },
    };
  }
}
