import {Injectable} from "@angular/core";
import {MenuConstService} from "./config/MenuConst";


@Injectable()
export class AppService {
    constructor(
        private menuConstService: MenuConstService,
    ) {
    }
}

