import {Injectable} from "@angular/core";
import {LocalDataService} from "ifocus-lib";
import {ConstantsHandler} from "../_common/_constant/constants.handler";


@Injectable()
export class SelfUserInfoService {

    constructor(
        private localDataService: LocalDataService,
    ) {
    }

    public setSelfUserInfo(user: any): void {
        this.localDataService.set(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER, user);
    }

    public getSelfUserInfo() {
        return this.localDataService.get(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER);
    }

    public selfUserInfo(): any {
        const user = this.localDataService.get<any>(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER);
        return user ? user : null;
    }

    public companyCode(): string {
        let code = '';
        const userInfo = this.selfUserInfo();
        if (userInfo) {
            code = userInfo.companyCode;
        }
        return code;
    }

    public getLocaleList(): any[] {
        return this.localDataService.get(ConstantsHandler.LOCAL_STORAGE.LOCALE_LIST);
    }

    public setLocaleList(localeList: any[]): void {
        this.localDataService.set(ConstantsHandler.LOCAL_STORAGE.LOCALE_LIST, localeList);
    }


}
